import React, { useMemo } from 'react'
import parse from 'html-react-parser'

import PanelLink from './PanelLinks/PanelLink/PanelLink'

const SectionIndexNextIndexLink = post => {
  const page = useMemo(() => {
    if (post?.sectionLink?.node?.wpChildren?.nodes?.length)
      return post.sectionLink.node.wpChildren.nodes.find(page => page.menuOrder === post.menuOrder + 1)
    return {title:''}
  }, [post])
  if (!page)
    return null
  else
    return <PanelLink uri={page.uri} number={page.menuOrder - 2}>
      {parse(page.title)}
    </PanelLink>
}

export default SectionIndexNextIndexLink
