import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

import { LINKS } from '../config/app'
import { useRecoilValue } from 'recoil'
import { recoilLang } from '../recoil/app'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BackLink from '../components/links/BackLink/BackLink'
import SectionIndexLinks from '../components/links/SectionIndexLinks'
import SectionIndexNextIndexLink from '../components/links/SectionIndexNextIndexLink'

import { Article, Footer } from '../components/common.styled'

import useFeaturedImage from '../hooks/useFeaturedImage'

const SectionIndexTemplate = ({data: {post}}) => {
  const lang = useRecoilValue(recoilLang)
  const featuredImage = useFeaturedImage(post)
  return <Layout lang={lang} featuredImage={featuredImage}>
    <Seo title={post.title} />
    <Article
        className={`page ${post.slug}`}
        itemScope
        itemType='http://schema.org/Article'
      >
        <header>
          <BackLink uri={post?.parent?.node?.uri} />
          <h1 itemProp='headline'>{parse(post.title)}</h1>
        </header>
        <section itemProp='articleBody'>
          {!!post.content && parse(post.content)}
          {post?.sectionIndexTemplateFields?.sectionIndexShowSectionLinks && <SectionIndexLinks {...post} />}
          <Footer>
            {LINKS.SECTION_INDEX.NEXT_INDEX && <SectionIndexNextIndexLink {...post} />}
          </Footer>
        </section>
      </Article>
  </Layout>
}

export default SectionIndexTemplate

export const pageQuery = graphql`
  query SectionIndexTemplate(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      content
      title
      menuOrder
      sectionIndexTemplateFields {
        sectionIndexShowNumbers
        sectionIndexPrefixNumbers
        sectionIndexShowSectionLinks
        sectionIndexTheme
      }
      parent: wpParent {
        node {
          slug
          uri
        }
      }
      sectionIndexLinks: wpChildren {
        nodes {
          ... on WpPage {
            title
            uri
            menuOrder
          }
        }
      }
      sectionLink: wpParent {
        node {
          ... on WpPage {
            wpChildren {
              nodes {
                ... on WpPage {
                  uri
                  title
                  menuOrder
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
